$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 50px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

/**** FROM STYLES SASS *****/
$font-primary: 'Open Sans', Arial, sans-serif;
// Overrides
$grid-gutter-width: 40px; 
$border-radius-base:  4px;
$padding-base-vertical: 14px;

$brand-primary: #99cccc;
$brand-secondary: #29222b; 

$brand-white: #fff;
$brand-black: #000;
$brand-lighter: #848484;
$brand-darker: #5a5a5a;
$brand-selection-color: $brand-primary;
$brand-body-color: #fcfcfc;
$brand-body-bg: #fff;
$brand-dark-footer: #222831;


$input-border-focus: $brand-primary;
$form-group-margin-bottom: 30px;



// Mixin
@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}

@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}
@mixin flex() {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;      
  	display: -moz-box;         
  	display: -ms-flexbox;      
  	display: -webkit-flex;     
  	display: flex;             
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; 
	-moz-flex-wrap: wrap; 
}
@import 'https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css';
@font-face {
	font-family: 'icomoon';
	src:url('../../fonts/icomoon/icomoon.eot?srf3rx');
	src:url('../../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('../../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('../../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	 
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}

@import '../../sass/bootstrap/mixins';
@import '../../sass/bootstrap/variables';


body {
	font-family: $font-primary;
	line-height: 1.6;
	font-size: 16px;
	background: $brand-body-bg;
	color: #848484;
	font-weight: 300;
	overflow-x: hidden;
	&.offcanvas {
		overflow: hidden;
	}
}
a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover{
		text-decoration: none !important;
		color: $brand-primary !important;
	}
}
p, ul, ol {
	margin-bottom: 1.5em;
	font-size: 16px;
	color: $brand-lighter;
	// font-weight: 300;
	font-family: $font-primary;
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-darker;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 30px 0;
}
figure {
	margin-bottom: 2em;
}
::-webkit-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::-moz-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

#header{
	// position: relative;
	margin-bottom: 0;
	z-index: 100;
}

.cover,
.hero {
	position: relative;
	height: 800px;
	// font-weight: 300;
	@media screen and (max-width :$screen-sm) {
		height: 700px;
	}
	&.hero-2{
		height: 600px;

		.overlay{
			position: absolute !important;
			width: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			background: rgba($brand-secondary, .5);
		}
	}
}
.overlay{
	position: absolute !important;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	// background: rgba(48,56,65,.92);
	// background: rgba(66,64,64,.9);
	background: rgba($brand-secondary, .5);
}

.cover{
	background-size: cover;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		height: inherit;
		padding: 3em 0;
	}

	&.cover_2{
		height: 600px;
		@media screen and (max-width: $screen-sm) {
			height: inherit;
			padding: 3em 0;
		}

	}

	.desc{
		top: 50%;
		position: absolute;
		width: 100%;
		margin-top: -80px;
		z-index: 2;
		color: $brand-white;
		
		@media screen and (max-width: $screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
		h2{
			color: $brand-white;
			text-transform: uppercase;
			font-size: 60px;
			margin-bottom: 10px;
			font-weight: 300!important;
			strong {
				font-weight: 700;
			}
			@media screen and (max-width: $screen-sm) {
				font-size: 40px;
			}
		}
		span{
			display: block;
			margin-bottom: 30px;

			font-size: 28px;
			letter-spacing: 1px;
			a{
				color: $brand-white;
				
				&:hover,&:focus{
					color: #fff !important;
					&.btn{
						border: 2px solid #fff !important;
						background: transparent !important;
					}
				}

				&.site-name{
					padding-bottom: 2px;
					border-bottom: 1px solid rgba($brand-white,.7);
				}
			}
		}
		.btn{
			border: none;
			&.btn-video{
				background: rgba($brand-black,.4);
				border: none;
				&:hover, &:focus{
					background: rgba($brand-black,.9) !important;
					color: $brand-white !important;
				}
			}
			&:hover, &:focus{
				color: $brand-white !important;
			}
			&.btn-learn{
				background: rgba($brand-primary,.7);
				border: none;
				&:hover, &:focus{
					background: rgba($brand-primary,.9) !important;
					color: $brand-white !important;
				}
			}
		}
	}
}

/* Superfish Override Menu */
.sf-menu {
	margin: 0!important;
	margin-top: 10px !important;
}

.sf-menu {
	float: right;
}
.sf-menu ul {
	box-shadow: none;
	border: transparent;
	min-width: 10em; 
	*width: 10em; 
}

.sf-menu a {
	color: rgba(255,255,255,.5);
	padding: .75em 1em!important;
	font-weight: normal;
	border-left: none;
	border-top: none; 
	border-top: none;
	text-decoration: none;
	zoom: 1; 
	font-size: 14px;
	border-bottom: none!important;

	&:hover,&:focus{
		color: #fff !important;
	}
}
.sf-menu li.active{
	a{
		&:hover,&:focus{
			color: #fff;
		} 
	}
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover  {
	background: transparent;
}
.sf-menu ul li a,
.sf-menu ul ul li a {
	text-transform: none;
	padding: .20em 1em!important;	
	letter-spacing: 1px;
	font-size: 14px!important;
}
.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
	color: $brand-white;
	// text-shadow: 2px 2px 1px rgba(0, 0, 0, .08);
}
.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
	background: transparent;
}


.sf-menu ul li {
	background: transparent;
}
.sf-arrows .sf-with-ul {
	padding-right: 2.5em;
	*padding-right: 1em;
}
.sf-arrows .sf-with-ul:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 1em;
	margin-top: -3px;
	height: 0;
	width: 0;
	border: 5px solid transparent;
	border-top-color: #ccc; 
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
	border-top-color: #ccc; 
}
.sf-arrows ul .sf-with-ul:after {
	margin-top: -5px;
	margin-right: -3px;
	border-color: transparent;
	border-left-color: #ccc;
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
	border-left-color: #ccc;
}

#header-section{
	padding:0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	// border-bottom: 1px solid rgba($brand-white,.2);
}
.nav-header{
	
	position: relative;
	float: left;
	width: 100%;
}
#logo{
	position: relative;
	z-index: 2;
	font-size: 22px;
	text-transform: uppercase;
	margin-top: 35px;
	font-weight: 700;
	float: left;
	span{
		font-weight: 700;
	}

	@media screen and (max-width: $screen-sm) {
		width: 100%;
		float: none;
		text-align: center;
	}

	a{
		color: $brand-white;
		&:hover,&:focus{
			color: $brand-white !important;
		}
	}
	
}

#menu-wrap {
	position: relative;
	z-index: 2;
	display: block;
	float: right;
	margin-top: 15px;
	.sf-menu a {
		padding: 1em .75em;
	}
}
#primary-menu {
	> li {
		> ul {
			li {
				&.active > a {
					color: $brand-primary!important;
				}
			}
		}
		> .sf-with-ul {
			&:after {
				border: none!important;
				@include icomoon();
				position: absolute;
				float: right;
				margin-right: -5px;
				// top: 15px;
				top: 50%;
				margin-top: -7px;
				content: "\e922";
				color: rgba(255,255,255,.5);
			}
		}
		> ul {
			li {
				position: relative;
				> .sf-with-ul {
					&:after {
						border: none!important;
						@include icomoon();
						position: absolute;
						float: right;
						margin-right: 10px;
						top: 20px;
						font-size: 16px;
						content: "\e924";
						color: rgba(0,0,0,.5);
					}
				}
			}
		}
	}	
}
#primary-menu .sub-menu {
	padding:10px 7px 10px;
	background: $brand-white;
	left: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-moz-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-ms-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-o-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	box-shadow:0 5px 15px rgba(0,0,0,0.15);
	> li {
		> .sub-menu {
			left: 100%;
		}	
	}
	
}

#primary-menu .sub-menu:before {
	position:absolute;
	top:-9px;
	left:20px;
	width:0;
	height:0;
	content:'';
}

#primary-menu .sub-menu:after {
	position:absolute;
	top:-8px;
	left:21px;
	width:0;
	height:0;
	border-right:8px solid transparent;
	border-bottom:8px solid $brand-white;
	border-bottom:8px solid rgba(255, 255, 255, 1);
	border-left:8px solid transparent;
	content:'';
}

#primary-menu .sub-menu .sub-menu:before {
	top:6px;
	left:100%;
}

#primary-menu .sub-menu .sub-menu:after {
	top:7px;
	right:100%;
	border: none!important;
}

.site-header.has-image #primary-menu .sub-menu {
	border-color:#ebebeb;
	-webkit-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-moz-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-ms-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-o-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	&:before {
		display:none;	
	}
}
#primary-menu .sub-menu a {
	letter-spacing: 0;
	padding:0 15px;
	font-size:18px;
	line-height:34px;
	color: #575757 !important;
	text-transform:none;
	background:none;
	&:hover {
		color: $brand-primary!important;
	}
}

#work-section,
#services-section,
#content-section,
#work-section_2,
#blog-section,
#features {
	padding: 7em 0;
	@media screen and (max-width: $screen-sm) {
		padding: 4em 0;
	}
}

#content-section {
	background: url(../img/banner.jpg) no-repeat center;
	background-size: cover;
}
.section-gray{
	background: rgba($brand-black,.04);
}

.heading-section{
	padding-bottom: 1em;
	margin-bottom: 1em;

	h2{
		color: $wht;
		font-size: 40px;
		font-weight: 100;
	}

	p {
		font-size: 20px;
	}
}


.services{
	// background: $brand-primary;
	padding: 40px 20px 20px 20px;
	margin-bottom: 30px;
	span{
		margin-bottom: 30px;
		// display: block;
		width: 80px;
		height: 80px;
		border: 1px solid $brand-primary;
		display: table;
		margin: 0 auto;
		@include border-radius(50%);
		margin-bottom: 20px;
		i{
			font-size: 30px;
			color: $brand-primary!important;
			display: table-cell;
			vertical-align: middle;
			height: 80px;
		}
	}
	h3{
		font-size: 20px;
		font-weight: 400;
	}
}

.feature-left {
	float: left;
	width: 100%;
	margin-bottom: 30px;
	position: relative;
	
	.icon {
		float: left;
		text-align: center;
		width: 15%;
		@media screen and (max-width: $screen-md) {
			width: 10%;
		}
		@media screen and (max-width: $screen-sm) {
			width: 15%;
		}
		i {
			display: table-cell;
			vertical-align: middle;
			font-size: 40px;
			color: $brand-primary;
		}
	}
	.feature-copy {
		float: right;
		width: 80%;
		@media screen and (max-width: $screen-md) {
			width: 90%;
		}
		@media screen and (max-width: $screen-sm) {
			width: 85%;
		}
		@media screen and (max-width: $screen-xs) {
			width: 80%;
		}

		h3 {
			font-size: 18px;
			color: lighten($brand-black, 10%);
			margin-bottom: 10px;
		}
		p {
			font-size: 16px;
			line-height: 1.8;
		}
	}
}


#portfolio {
	padding: 7em 0;
	// background: #303841;
	min-height: 500px;
	@media screen and (max-width: $screen-sm) {
		padding: 3em 0;
	}
	
}
#portfolio-list {
	@include flexwrap;
	@include flex;
	position: relative;
	float: left;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		display: block;
		padding: 0;
		margin: 0 0 10px 1%;
		list-style: none;
		min-height: 400px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		float: left;
		clear: left;
		position: relative;
		@media screen and (max-width: $screen-xs) {
			margin-left: 0;
		}
		a {
			min-height: 400px;
			padding: 2em;
			position: relative;
			width: 100%;
			display: block;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: "";
				z-index: 80;
				@include transition(.5s);

				background: rgba(0, 0, 0, .0);	

			}
			&:hover {
				&:before {
					background: rgba(0, 0, 0, .5);	
				}
			}
			
			.case-studies-summary {
			   width: auto;
			   bottom: 2em;
			   left: 2em;
			   right: 2em;
			   position: absolute;
			   z-index: 100;
			   @media screen and (max-width: $screen-sm) {
			   	bottom: 1em;
				   left: 1em;
				   right: 1em;
			   }
			   span {
			   	text-transform: uppercase;
			   	letter-spacing: 2px;
			   	font-size: 13px;
			   	color: rgba(255,255,255,.7);
			   }
			   h2 {
			   	color: $brand-white;
			   	margin-bottom: 0;
			   	@media screen and (max-width: $screen-sm) {
			   		font-size: 20px;
			   	}
			   }

			}
		}
		&.one-third {
			width: 32.3%;
			@media screen and (max-width: $screen-sm) {
				width: 49%;	
			}
			@media screen and (max-width: $screen-xs) {
				width: 100%;	
			}
		}
		&.one-half {
			width: 49%;
			@media screen and (max-width: $screen-xs) {
				width: 100%;	
			}
		}
		
	}
	
}

#partner{
	margin-top: -3em;
	float: left;
	width: 100%;
	@media screen and (max-width: $screen-sm) {
		margin-top: 0;
	}
	.partner-wrap{
		position: relative;
		z-index: 98;
		float: left;
		width: 100%;
	}
	.wrap{
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		padding: 30px;
		background: $brand-white;
		-webkit-box-shadow: 0px 9px 25px -8px rgba(0,0,0,0.38);
		-moz-box-shadow: 0px 9px 25px -8px rgba(0,0,0,0.38);
		-ms-box-shadow: 0px 9px 25px -8px rgba(0,0,0,0.38);
		-o-box-shadow: 0px 9px 25px -8px rgba(0,0,0,0.38);
		box-shadow: 0px 9px 25px -8px rgba(0,0,0,0.38);
		border-radius: $border-radius;

		h1,h2,h3,h4 {
			margin: 0px;
		}
	}
	.partner{
		width: 20%;
		float: left;
		.inner{
			width: 50%;
			margin: 0 auto;
		}
	}
}


#features {
	margin-top: -11em;
	padding: 8em 0;
	background: #f5f5f5;
	@media screen and (max-width: $screen-md){
		margin-top: 0;
		padding: 7em 0;
	}
	.feature-left {
		margin-bottom: 0;
		@media screen and (max-width: $screen-md) {
			margin-bottom: 4em;
		}
		.icon {
			i {
				color: lighten($brand-black, 70%);
			}
		}

		.icon {
			float: left;
			text-align: center;
			width: 15%;
			@media screen and (max-width: $screen-md) {
				width: 10%;
			}
			@media screen and (max-width: $screen-sm) {
				width: 15%;
			}
		}
		.feature-copy {
			float: right;
			width: 80%;
			@media screen and (max-width: $screen-md) {
				width: 90%;
			}
			@media screen and (max-width: $screen-sm) {
				width: 85%;
			}
			@media screen and (max-width: $screen-xs) {
				width: 80%;
			}
		}
		h3 {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
		}
		p {
			font-size: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#services, #about, #contact {
	padding: 7em 0;
	@media screen and (max-width: $screen-sm) {
		padding: 4em 0;
	}
}

#work{
	// padding-bottom: 7em;
	margin-top: -3.9em;
	@media screen and (max-width: $screen-sm) {
		margin-top: 11em;
	}
	.work{
		width: 100%;
		float: left;
		margin-bottom: 7em;
		.half-inner{
			background-size: cover;
			background-position: center center;
			position: relative;
			height: 500px;
			width: 135%;
			-webkit-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
			-moz-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
			-ms-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
			-o-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
			box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
			@media screen and (max-width: $screen-md){
				width: 90%;
				margin: 0 auto;
			}
			&:before{
				position: absolute;
				top: -20px;
				right: -20px;
				width: 80%;
				height: 540px;
				border: 1px solid rgba(0,0,0,.1);;
				content: '';
				z-index: -1;
				background: $brand-white;
				// @include border-radius(4px);
			}
			&.half-inner2{
				width: 100% !important;
				height: 500px !important;
				&:before{
					left: -20px;
				}
				@media screen and (max-width: $screen-md){
					width: 90% !important;
					margin: 0 auto;
				}
			}
		}
		.desc{
			width: 70%;
			// margin-left: 6em;
			margin: 0 auto;
			margin-top: 7em;
			h3{
				a{
					color: $brand-black;
				}
			}
			.btn{
				// @include border-radius(4px);
			}
		}
	}
}


#services {
	.number {
		font-size: 30px;
		color: $brand-primary;
		position: relative;
		padding-top: 10px;
		font-style: italic;
		@include inline-block;
		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: 3px;
			top: 0;
			left: 0;
			background: $brand-primary;

		}
	}
	.icon {
		margin-bottom: 30px;
		margin-top: 50px;
		position: relative;
		@include inline-block;
		@media screen and (max-width: $screen-sm) {
			margin-top: 0px;
			float: none;
		}
		i {
			font-size: 200px;
			color: $brand-primary;
			@media screen and (max-width: $screen-sm) {
				font-size: 100px;
			}
		}
	}
}

#contact {
	.contact-info{
		margin: 0;
		padding: 0;
		li{
			// font-size: 16px;
			list-style: none;
			margin: 0 0 20px 0;
			position: relative;
			padding-left: 40px;

			i{
				position: absolute;
				top: .3em;
				left: 0;
				font-size: 22px;
				color: rgba($brand-black,.3);
			}
			a{
				font-weight: 400!important;
				color: rgba($brand-black,.3)
			}
			
		}
	}
	.form-control {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-ms-box-shadow: none;
		-o-box-shadow: none;
		box-shadow: none;
		border: none;
		border: 1px solid rgba(0,0,0,.1);
		font-size: 18px;
		
		font-weight: 400;
		@include border-radius(0px);
		
		&:focus, &:active {
			border-bottom: 1px solid rgba(0,0,0,.5);
		}

	}
	input[type="text"] {
		height: 50px;
	}
	.form-group {
		margin-bottom: 30px;
	}
}

.testimony{
	width: 100%;
	float: left;
	figure{
		width: 50px;
		height: 50px;
		float: left;
		margin-left: 22px;
		img{
			width: 50px;
			height: 50px;
			@include border-radius(50%);
		}
	}
	blockquote {
    border-left: none;
    padding: 40px;
    background: #fff;
    -webkit-box-shadow: 5px 6px 7px -6px rgba(0,0,0,0.22);
	-moz-box-shadow: 5px 6px 7px -6px rgba(0,0,0,0.22);
	-ms-box-shadow: 5px 6px 7px -6px rgba(0,0,0,0.22);
	-o-box-shadow: 5px 6px 7px -6px rgba(0,0,0,0.22);
	box-shadow: 5px 6px 7px -6px rgba(0,0,0,0.22);
    position: relative;
    @include border-radius(4px);
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 12px 12px 0 12px;
      border-color: #fff transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -12px;
      left: 10%;
    }
  }
  .author {
    margin-bottom: 0;
    margin-left: 15px;
    margin-top: 10px;
    float: left;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
  }
}

// .testimonial{
// 	position: relative;
// 	background: $brand-white;
// 	padding: 4em 0;
// 	border-radius: 5px;
// 	-webkit-box-shadow: 0px 10px 20px -12px rgba(0,0,0,0.18);
// 	-moz-box-shadow: 0px 10px 20px -12px rgba(0,0,0,0.18);
// 	box-shadow: 0px 10px 20px -12px rgba(0,0,0,0.18);

// 	@media screen and (max-width: $screen-sm) {
// 		border-right: 0;
// 		margin-bottom: 80px;
// 	}

// 	h2{
// 		font-size: 16px;
// 		letter-spacing: 2px;
// 		text-transform: uppercase;
// 		font-weight: bold;
// 		margin-bottom: 50px;
// 	}
// 	figure{
// 		position: absolute;
// 		top: -50px;
// 		left: 50%;
// 		margin-left: -44px;
// 		margin-bottom: 20px;
// 		background-size: cover;


// 		img{
// 			width: 100px;
// 			height: 100px;
// 			border: 10px solid $brand-white;
// 			background-size: cover;
// 			@include border-radius(50%);
// 		}
// 	}

// 	blockquote{
// 		border: none;
// 		margin-bottom: 20px;
// 		p{
// 			font-style: italic;
// 			font-size: 22px;
// 			line-height: 1.6em;
// 			color: #555555;
// 		}
// 	}
// 	span{
// 		font-style: italic;
// 	}
// }

.blog{
	margin-bottom: 60px;
	a{
		img{
	
			width: 100%;

			&:hover{
			}

		}
	}

	@media screen and (max-width: $screen-sm) {
		width: 100%;
	}
	

	.blog-text {
		margin-bottom: 30px;
		position: relative;
		background: $brand-white;
		width: 100%;
		padding: 30px;
		float: right;

		-webkit-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
		-ms-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
		-o-box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);
		box-shadow: 0px 6px 62px -6px rgba(0,0,0,0.3);

		span{
			display: inline-block;
			margin-bottom: 20px;
			&.posted_by{
				color: rgba($brand-black,.3);
				font-size: 18px;
			}
			&.comment{
				float: right;
				a{
					color: rgba($brand-black,.3);
					i{
						color: $brand-primary;
						padding-left: 7px;
					}
				}
			}
		}

		h3{
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: 300;
			line-height: 1.5;
			a{
				color: rgba($brand-black,.6);
			}
		}

		p{
			// color: rgba($brand-white,.5);
		}
	}
}

#start-project-section{
	padding: 7em 0;
	background: #F64E8B;
	h2{
		color: rgba(255,255,255,1);
		font-size: 60px;
		text-transform: uppercase;
		font-weight: 300;
		@media screen and (max-width: $screen-sm) {
			font-size: 30px;
		}
	}
	a{
		&:hover, &:focus, &:active {
			color: rgba(255,255,255,1)!important;
		}
	}
}


// Map
#map {
	width: 100%;
	height: 500px;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		height: 200px;
	}
}

.social-icons {
	> a {
		padding: 4px 10px;
		color: $brand-primary;

		i{
			font-size: 26px;
		}
		&:hover, &:focus, &:active {
			text-decoration: none;
			color: $brand-darker;
		}
	}
}

#footer{
	background: #2F3032;
	padding: 6em 0 0 0;
	width: 100%;
	float: left;
	@media screen and (max-width: $screen-sm) {
		padding: 3em 0;
	}
	h2, h3, h4 {
		color: rgba($brand-white,.8);
		text-transform: uppercase;
	}
	h3 {
		font-size: 16px;
	}
	[class*="col"] {
		padding-bottom: 30px;
	}
	.btn {
		color: $brand-white;
	}
	.float {
		float: left;
		margin-right: 10%;
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 0;
			margin: 0 0 10px 0;
			list-style: none;
			a {
				color: rgba(255,255,255,.4);
				text-decoration: none!important;
				&:hover {
					color: $brand-white;
				}
			}
		}
	}
	.social {
		li {
			display: block !important;
			a{
				font-size: 16px;
			}
			i{
				float: left;
				font-size: 20px;
				width: 40px;
			}
		}
	}
	.copyright {
		border-top: 1px solid rgba(255,255,255,.1);
		// clear: both;
		margin-top: 40px!important;
		padding: 3em;
		width: 100%;
		float: left;
		span {
			display: block;
			a{
				color: rgba($brand-white,.8);
			}
		}
		@media screen and (max-width: $screen-xs) {
			text-align: left!important;
			span {
				display: inline;
			}
		}
	}
}


// Buttons
.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	@include border-radius(30px);
	@include transition(.5s);
	padding: 8px 20px;
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	background: $brand-primary;
	color: $brand-white;
	border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: lighten($brand-primary, 5%)!important;
			border-color: lighten($brand-primary, 5%)!important;
			color: $brand-white !important;
		}
	i {
		font-weight: bold;
	}		

	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $brand-success;
	color: $brand-white;
	border: 2px solid $brand-success;
	&:hover, &:focus, &:active {
		background: darken($brand-success, 5%)!important;
		border-color: darken($brand-success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-success;
		border: 2px solid $brand-success;
		&:hover, &:focus, &:active {
			background: $brand-success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $brand-info;
	color: $brand-white;
	border: 2px solid $brand-info;
	&:hover, &:focus, &:active {
		background: darken($brand-info, 5%)!important;
		border-color: darken($brand-info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-info;
		border: 2px solid $brand-info;
		&:hover, &:focus, &:active {
			background: $brand-info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $brand-warning;
	color: $brand-white;
	border: 2px solid $brand-warning;
	&:hover, &:focus, &:active {
		background: darken($brand-warning, 5%)!important;
		border-color: darken($brand-warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-warning;
		border: 2px solid $brand-warning;
		&:hover, &:focus, &:active {
			background: $brand-warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $brand-danger;
	color: $brand-white;
	border: 2px solid $brand-danger;
	&:hover, &:focus, &:active {
		background: darken($brand-danger, 5%)!important;
		border-color: darken($brand-danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-danger;
		border: 2px solid $brand-danger;
		&:hover, &:focus, &:active {
			background: $brand-danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

.btn.with-arrow {
	position: relative;
	@include transition(.3s);
	i {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -8px;
		@include transition(.2s);
	}
	&:hover {
		padding-right: 50px;
		i {
			color: $brand-white;
			right: 18px;
			visibility: visible;
			opacity: 1;
		}
	}
}

.animate-box {
	.js & {
		opacity: 0;
	}
}



#wrapper {
	overflow-x: hidden;
	position: relative;
}
.offcanvas {
	overflow: hidden;
	#wrapper {
		overflow: hidden!important;
	}
}

#page {
	position: relative;
	@include transition(.5s);
	.offcanvas & {
		@include translateX(-240px);
	}
}

// offcanvas
#menu-wrap {
	@media screen and (max-width: $screen-sm) {
		display: none;
	}
}
#offcanvas-menu {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 99;
	background: #222;
	right: -240px;
	width: 240px;
	padding: 20px;
	overflow-y: scroll!important;
	@media screen and (max-width: $screen-sm) {
		// display: block;
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 0;
			margin: 0 0 10px 0;
			list-style: none;
			a {
				font-size: 18px;
				color: rgba(255,255,255,.4);
				&:hover {
					color: $brand-primary;
				}
			}
			> .sub-menu {
				margin-top: 9px;
				padding-left: 20px;
			}
			&.active {
				> a {
					color: $brand-primary;
				}
			}
		}
		.sub-menu {
			display: block!important;
		}
	}
}



// Burger Menu
.nav-toggle {
 
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-white;
		}
  }
  &.dark {
  	&.active i {
			&::before, &::after {
				background: $brand-white;
			}
	  }
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  @include inline-block;
	  width: 30px;
	  height: 2px;
	  color: $brand-white;
	  font:bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $brand-white;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 30px;
		  height: 2px;
		  background: $brand-white;
		  position: absolute;
		  left:0;
		  @include transition(.2s);
	  }
  }
  &.dark {
  	i {
	  	position: relative;
		  color: $brand-black;
		  background: $brand-black;
		  transition: all .2s ease-out;
			 &::before, &::after {
			  background: $brand-black;
			  @include transition(.2s);
		  }
	  }
  }
}

.nav-toggle i::before {
  top: -7px;
}
.nav-toggle i::after {
  bottom: -7px;
}
.nav-toggle:hover i::before {
  top: -10px;
}
.nav-toggle:hover i::after {
  bottom: -10px;
}
.nav-toggle.active i {
	background: transparent;
}
.nav-toggle.active i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.nav-toggle.active i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.nav-toggle {
  	position: absolute;
  	top: 0px;
	right: 0px;
	z-index: 9999;
	display: block;
	margin: 0 auto;
	display: none;
	cursor: pointer;
  	@media screen and (max-width: $screen-sm) {
  		display: block;
  		top: 0px;
  		margin-top: 2em;
  	}
  
}



// // Helper Class

.col-xxs-12 {
	@media screen and (max-width: 480px) {
		float: none;
		width: 100%;
	}
}

.row-bottom-padded-lg {
	padding-bottom: 7em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}
}
.row-top-padded-lg {
	padding-top: 7em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}
}
.row-bottom-padded-md {
	padding-bottom: 4em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}	
}
.row-top-padded-md {
	padding-top: 4em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}	
}
.row-bottom-padded-sm {
	padding-bottom: 2em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}	
}
.row-top-padded-sm {
	padding-top: 2em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}	
}

.border-bottom {
	border-bottom: 1px solid rgba($brand-black,.06);
}

.lead {
	line-height: 1.8;
}

.no-js #loader { 
	display: none;  
}
.js #loader { 
	display: block; position: absolute; left: 100px; top: 0; 
}
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(../img/hourglass.svg) center no-repeat #222222;
}


strong {
	color: $brand-primary;
	text-transform: uppercase;
}










.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.btn {
	i {
		padding-right: 10px;
	}
}

.navbar {
	margin-bottom: 0px;
	position: fixed;
	width: 100%;
	background: rgba(255,255,255,0.9);
	border-radius: 0px;
	border: none;

	.logo {
		max-width: 200px;
		padding: 10px 0px;
	}

	.navbar-nav {
	    > li > a {
    		font-weight: bold;
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
			    height: 45px;
			    display: inline-block;
			}

		    &:hover, &:focus {
		    	background: $brand-primary;
		    	color: $wht !important;
		    }
		}
	}	
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid $blk;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}

	span.icon-bar {
		background-color: $blk;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $brand-primary;
	border-radius: $border-radius;
	background: $brand-primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($brand-primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $brand-primary;
	border: none;

	&:hover {
		background: $wht;
		color: $brand-primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $brand-primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($brand-primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $brand-primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $brand-primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

hr {
	width: 150px;
	border-width: 2px;
	border-color: $brand-primary;
}

footer {
	color: $blk;
	padding-bottom: 0px;

	a,p,span {
		color: $blk;
	}

	a:hover {
		color: lighten($blk,10%) !important;
	}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid $brand-primary;
	-webkit-text-fill-color: $brand-primary;
	-webkit-box-shadow: 0 0 0px 1000px $brand-primary inset;
	transition: background-color 5000s ease-in-out 0s;
}

#banner {
	background: url(../img/cover_bg_1.jpg) no-repeat center;
	background-size: cover;
}

#bgImg1 {
	background: url(../img/work-1.jpg) no-repeat center;
	background-size: cover;

    @media (max-width: 600px) {
      background-position: 70% 0% !important;
    }  	
}

#bgImg2 {
	background: url(../img/work-2.jpg) no-repeat center;
	background-size: cover;
    @media (max-width: 600px) {
      background-position: 70% 0% !important;
    }  		
}

.top-pad {
	padding-top: 3.25em;
}

@media(max-width: 767px) {
	.navbar .logo {
		margin-left: 1em;
		max-width: 150px;
	}

	.navbar-toggle {
		margin-top: 15px;
	}

    .navbar .navbar-nav > li > a {
	    height: 40px;
	    display: block;
		text-align: center;
  	}
}

